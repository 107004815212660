import React from 'react'
import styled, { css } from 'styled-components'

const StyledIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 300ms ease-in-out;
`

const StyledHoverIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
`

const Container = styled.div`
  position: absolute;
  z-index: 10;
  cursor: pointer;

  ${props =>
    props.hasHover
      ? css`
          &:hover ${StyledIcon} {
            opacity: 0;
          }

          &:hover ${StyledHoverIcon} {
            opacity: 1;
          }
        `
      : props.isActive &&
        css`
          ${StyledIcon} {
            opacity: 0;
          }

          ${StyledHoverIcon} {
            opacity: 1;
          }
        `}
`

const HoverIconContainer = ({
  Icon,
  HoverIcon,
  ActiveIcon,
  closeMenu,
  isActive,
}) => {
  return (
    <Container onClick={closeMenu} hasHover={!!HoverIcon} isActive={isActive}>
      <StyledIcon as={Icon} />
      {HoverIcon && <StyledHoverIcon as={HoverIcon} />}
      {ActiveIcon && <StyledHoverIcon as={ActiveIcon} />}
    </Container>
  )
}

export default HoverIconContainer
