import { css } from 'styled-components'

export const colors = {
  'sky-blue': '#3db9ec',
  grey: '#8a8a8a',
  'lightest-grey': '#ececec',
  'light-grey': '#a7a7a7',
  'dark-blue': '#294b52',
  white: '#ffffff',
  inputGrey: '#4c4b4b'
}

export const fonts = {
  sans: 'Montserrat',
  serif: 'Bebas Neue',
}

export const sizes = {
  xs: 480,
  sm: 576,
  md: 780,
  lg: 992,
  xl: 1200,
}

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `
  acc[`${label}Max`] = (...args) => css`
    @media screen and (max-width: ${sizes[label] - 1}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})
