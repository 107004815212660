import React, { Component } from 'react'
import styled from 'styled-components'
import throttle from 'lodash/throttle'
import HoverIconContainer from './hoverIconContainer'
import Nav from './nav'
import MenuIcon from '../images/menu-lateral.svg'
import MenuIconHover from '../images/menu-lateral-hover.svg'
import MenuMobileIcon from '../images/men-m-vil.svg'
import { media } from '../variables'

const IconMenuContent = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  left: 30px;
  ${media.mdMax`
    display: none;
  `}
`

const IconMobileContent = styled.div`
  display: none;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 20px;
  right: 20px;
  ${media.mdMax`
    display: block;
  `}
`

const MenuContent = styled.div`
  background-color: #294b52;
  width: calc(100vw + 100px);
  height: 100vh;
  display: flex;
  align-items: center;
  position: fixed;
  transition: right 300ms cubic-bezier(1, 0.01, 0.625, 1);
  right: ${props => {
    if (props.openedMenu) {
      return 0
    }
    if (!props.openedMenu) {
      if (props.toggleMenu) {
        return '-100vw'
      }
    }
    return 'calc(-100vw + -80px)'
  }};

  z-index: 1000;

  ${media.mdMax`
    width: 100vw;
    right: calc(-100vw + -100px);
    transition: right 300ms cubic-bezier(1, 0.01, 0.625, 1);
    right: ${props => (props.openedMenu ? '0' : 'calc(-100vw + -100px)')};

    ${IconMobileContent} {
      z-index: ${props => (props.openedMenu ? '-1' : '10')};
      transition: opacity 400ms;
      opacity: ${props => (props.openedMenu ? '0' : '1')};
    }
  `}
`

class Menu extends Component {
  constructor() {
    super()
    this.state = {
      openedMenu: false,
    }
    this.mouseMove = throttle(this.mouseMove.bind(this), 50)
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.mouseMove)
  }

  componentWillUnmount() {
    window.removeEventListener('movemouse', this.mouseMove)
  }

  handleClick = () => {
    const { openedMenu } = this.state
    this.setState({
      openedMenu: !openedMenu,
    })
  }

  closeMenu = () => {
    this.setState({
      openedMenu: false,
    })
  }

  mouseMove = e => {
    const { scroll, toggle } = this.props
    const { innerWidth } = window

    if (scroll === 'up') {
      return
    }

    if (Date.now() - scroll > 1000) {
      if (e.clientX >= innerWidth - 220) {
        toggle(true)
      } else {
        toggle(false)
      }
    }
  }

  render() {
    const { section, toggleMenu } = this.props
    const { openedMenu } = this.state
    return (
      <>
        <MenuContent openedMenu={openedMenu} toggleMenu={toggleMenu}>
          <IconMenuContent onClick={this.handleClick}>
            <HoverIconContainer Icon={MenuIcon} HoverIcon={MenuIconHover} />
          </IconMenuContent>
          <IconMobileContent onClick={this.handleClick}>
            <HoverIconContainer
              Icon={MenuMobileIcon}
              HoverIcon={MenuMobileIcon}
            />
          </IconMobileContent>
          <Nav
            closeMenu={this.closeMenu}
            currentSection={section}
            openedMenu={openedMenu}
          />
        </MenuContent>
      </>
    )
  }
}

export default Menu
