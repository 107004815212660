import React from 'react'
import styled from 'styled-components/macro'

import WhiteLogo from 'images/whiteLogo.svg'
import ColorLogo from 'images/colorLogo.svg'
import { colors } from '../variables'
import HoverIconContainer from './hoverIconContainer'
import { Container, InnerContainer } from './common'

const StyledContainer = styled(Container)`
  position: fixed;
  z-index: 999;
  background: transparent;
  top: 20px;
`

const StyledInnerContainer = styled(InnerContainer)`
  padding-bottom: 0;
`

const Title = styled.h1`
  color: ${props => (props.isActive ? colors['dark-blue'] : colors.white)};
  margin: 0;
  font-size: 1rem;
  font-family: Montserrat;
  font-weight: 300;
  display: inline-block;
  opacity: ${props => (props.showTitle ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
`

const LogoContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
  width: 72px;
  height: 63px;
`

const Header = ({ showTitle, isActive }) => {
  return (
    <StyledContainer>
      <StyledInnerContainer>
        <LogoContainer id="logo-up">
          <HoverIconContainer
            Icon={WhiteLogo}
            ActiveIcon={ColorLogo}
            isActive={isActive}
          />
        </LogoContainer>
        <Title isActive={isActive} showTitle={showTitle}>
          Servicios de Consutoría | Tecnología
        </Title>
      </StyledInnerContainer>
    </StyledContainer>
  )
}

export default Header
