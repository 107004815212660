import React from 'react'
import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { InnerContainer } from 'components/common'
import MenuOption from './menuOption'
import HoverIconContainer from './hoverIconContainer'
import Close from '../images/cerrar.svg'
import CloseHover from '../images/cerrar-hover.svg'
import { media } from '../variables'

const LinkFacebook = styled(AnchorLink)`
  color: #3db9ec;
  font-size: 20px;
  position: absolute;
  right: 0;
  bottom: -26px;
  ${media.mdMax`
    font-size: 20px;
    position: absolute;
    right: auto;
    bottom: -15%;
  `}
`

const LabelMenu = styled.p`
  display: inline-block;
  color: #3db9ec;
  font-size: 30px;
  ${media.mdMax`
    font-size: 20px;
  `}
`

const IconCloseContent = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  ${media.mdMax`
    left: auto; 
    right: 20px;
    top: 20px;
    position: fixed;
    transform: none;
  `}
`

const NavContent = styled.div`
  transition: opacity 300ms ease-in-out;
  opacity: ${props => (props.openedMenu ? 1 : 0)};
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 80px;
  ${media.mdMax`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 40rem;
    padding-left: 0;
  `}

  ${media.lgMax`
    max-width: 50rem;
  `}
`

const InnerContent = styled(InnerContainer)`
  padding: 0 0;
  transform: translateX(50px);

  ${media.mdMax`
    transform: none;
  `}
`

const Nav = ({ closeMenu, currentSection, openedMenu }) => {
  return (
    <InnerContent>
      <NavContent openedMenu={openedMenu}>
        <IconCloseContent>
          <HoverIconContainer
            Icon={Close}
            HoverIcon={CloseHover}
            closeMenu={closeMenu}
          />
        </IconCloseContent>
        <LinkFacebook>FACEBOOK</LinkFacebook>
        <LabelMenu>MENÚ</LabelMenu>
        <MenuOption
          child="INICIO"
          link="#home"
          width="300px"
          closeMenu={closeMenu}
          activeSection={currentSection === 'home'}
        />
        <MenuOption
          child="NOSOTROS"
          link="#about"
          width="300px"
          closeMenu={closeMenu}
          activeSection={
            currentSection === 'about' || currentSection === 'about-sec-two'
          }
        />
        <MenuOption
          child="SERVICIOS"
          link="#service"
          width="300px"
          closeMenu={closeMenu}
          activeSection={currentSection === 'service'}
        />
        <MenuOption
          child="CONTACTANOS"
          link="#contact"
          width="400px"
          closeMenu={closeMenu}
          activeSection={currentSection === 'contact'}
        />
      </NavContent>
    </InnerContent>
  )
}

export default Nav
