import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import styled from 'styled-components'
import { colors, media } from '../variables'

const CloneText = styled.span`
  position: absolute;
  text-decoration: none;
  color: #ffffff;
  font-family: 'Bebas Neue';
  font-size: 80px;
  letter-spacing: 5px;
`

const LabelContainer = styled.span`
  height: 80px;
  background-color: #3db9ec;
  transition: width 0.5s;
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 0px;
`

const LinkContainer = styled(({ isActive, ...props }) => (
  <AnchorLink {...props} />
))`
  color: ${props => (props.isActive ? colors['sky-blue'] : colors.white)};
  text-decoration: none;
  font-family: 'Bebas Neue';
  padding: 0 0px;
  font-size: 80px;
  letter-spacing: 5px;
  &:hover {
    ${LabelContainer} {
      width: ${props => props.width};
    }
  }

  ${media.mdMax`
    font-size: 50px;
    letter-spacing: 3.13px;
    ${LabelContainer} {
      display: none;
    }
    ${CloneText} {
      display: none;
    }
  `}
`

const DivContainer = styled.div`
  line-height: 1;
  position: relative;
  margin-bottom: 10px;
  ${media.mdMax`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

const MenuOption = ({ child, width, link, closeMenu, activeSection }) => {
  return (
    <DivContainer>
      <LinkContainer
        width={width}
        href={link}
        onClick={closeMenu}
        isActive={activeSection}>
        {child}
        <LabelContainer>
          <CloneText>{child}</CloneText>
        </LabelContainer>
      </LinkContainer>
    </DivContainer>
  )
}

export default MenuOption
