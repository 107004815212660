import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'styled-components/macro'
import throttle from 'lodash/throttle'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Menu from './menu'
import './layout.css'

const Content = styled.div`
  color: white;
`

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      section: 'home',
      scroll: null,
      toggleMenu: true,
      scrollPos: 0,
      changeLogoColor: false,
    }
    this.onScroll = throttle(this.onScroll.bind(this), 50)
  }

  componentDidMount() {
    this.home = document.getElementById('home')
    this.about = document.getElementById('about')
    this.aboutSecondSection = document.getElementById('about-section-two')
    this.service = document.getElementById('service')
    this.contact = document.getElementById('contact')
    this.logo = document.getElementById('logo-up')
    this.onScroll()
    document.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    const rectsHome = this.home.getClientRects()
    const rectsAbout = this.about.getClientRects()
    const rectsAboutSectionTwo = this.aboutSecondSection.getClientRects()
    const rectsService = this.service.getClientRects()
    const rectsContact = this.contact.getClientRects()

    const { top } = rectsHome[0]
    const { top: topAbout } = rectsAbout[0]
    const {
      top: topAboutSecTwo,
      bottom: bottomAboutSecTwo,
    } = rectsAboutSectionTwo[0]
    const { top: topService } = rectsService[0]
    const { top: topContact } = rectsContact[0]
    const { section, scrollPos } = this.state

    if (
      document.body.getBoundingClientRect().top > scrollPos ||
      section === 'home'
    ) {
      this.setState({ scroll: 'up', toggleMenu: true })
    } else {
      this.setState({ scroll: Date.now(), toggleMenu: false })
    }
    this.setState({ scrollPos: document.body.getBoundingClientRect().top })

    if (top < 1 && topAbout > 0) {
      this.setState({ section: 'home' })
    } else if (topAbout < 1 && topAboutSecTwo > 0) {
      this.setState({ section: 'about' })
    } else if (topAboutSecTwo < 1 && topService > 0) {
      this.setState({ section: 'about-sec-two' })
    } else if (topService < 1 && topContact > 20) {
      this.setState({ section: 'service' })
    } else {
      this.setState({ section: 'contact' })
    }

    if (topAbout > 100) {
      this.setState({ changeLogoColor: false })
    } else if (topAbout - 36 < -2 && bottomAboutSecTwo > 100) {
      this.setState({ changeLogoColor: true })
    } else if (bottomAboutSecTwo <= 36 && topContact > 100) {
      this.setState({ changeLogoColor: false })
    } else if (topContact < 36) {
      this.setState({ changeLogoColor: true })
    }
  }

  toggle = value => {
    this.setState({ toggleMenu: value })
  }

  render() {
    const { children } = this.props
    const { section, scroll, toggleMenu, changeLogoColor } = this.state

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: data.site.siteMetadata.title },
                { name: 'keywords', content: data.site.siteMetadata.keywords },
              ]}>
              <html lang="es" />
            </Helmet>
            <Header
              showTitle={section === 'home' || section === 'contact'}
              isActive={changeLogoColor}
            />
            <Menu
              section={section}
              scroll={scroll}
              toggleMenu={toggleMenu}
              toggle={this.toggle}
            />
            <Content>{children}</Content>
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
