import styled, { css } from 'styled-components'
import Image from 'gatsby-image'
import { colors, media } from '../variables'
import Icon from '../images/siguiente.svg'

export const Container = styled.div`
  width: 100%;
  background: ${colors.white};
`

export const InnerContainer = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  color: ${colors['dark-blue']};
  padding-bottom: 4rem;

  ${media.sm`
    max-width: 30rem;
  `}

  ${media.md`
    max-width: 40rem;
  `}

  ${media.lg`
    max-width: 50rem;
  `}

  ${media.xl`
    max-width: 60rem;
  `}
`

export const Subtitle = styled.h3`
  padding-top: 13.5rem;
  font-size: 1.875rem;
  color: ${colors['sky-blue']};

  ${media.mdMax`
    padding-top: 8rem;
  `}
`

export const Title = styled.h1`
  font-size: 8.76rem;
  font-weight: 200;
  margin-top: 1rem;

  ${media.mdMax`
    font-size: 5rem;
  `}

  ${props =>
    props.light &&
    css`
      color: ${colors.white};
      font-weight: 100;
    `}
`

export const Info = styled.h4`
  color: ${colors['light-grey']};
  font-weight: 100;
  font-family: Montserrat;
  width: 100%;
  margin-bottom: 6rem;

  ${media.mdMax`
    margin-bottom: 3rem;
    text-align: center;
  `}

  ${media.lg`
    max-width: 37.5rem;
  `}
`

export const Background = styled(Image)`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute !important;
`

export const Button = styled.button`
  border: none;
  background: none;
  font-size: 80px;
  font-family: 'Bebas Neue';
  transition: color 150ms cubic-bezier(1, 0.01, 0.625, 1);
  color: ${props => (props.isActive ? '#3db9ec' : '#ececec')};
  line-height: 80px;
  -webkit-appearance: none;
  text-align: left;
  cursor: pointer;
  padding: 15px 10px 10px;

  &:focus {
    outline: 1px solid;
  }
`

export const StyledContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  right: ${props => (props.isOpen ? '0' : '-100vw')};
  transition: right 300ms cubic-bezier(1, 0.01, 0.625, 1);
  z-index: 20;

  ${media.mdMax`
    z-index: 1000;
  `}
`

export const StyledContainerLeft = styled.div`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: relative;

  &:active {
    border-top: solid 10px #3db9ec;
    border-bottom: solid 10px #3db9ec;
    border-left: solid 35px #3db9ec;
  }
`

export const StyledContainerRight = styled.div`
  width: 80vw;
  height: 100vh;
  padding: 0 80px;
  background: ${colors['dark-blue']};
  transform: skew(155deg);
  position: absolute;
  right: -350px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  ${media.xlMax`
    justify-content: start;
  `}

  ${media.lgMax`
    padding: 0 30px;
  `}

  ${media.mdMax`
    justify-content: center;
    transform: none;
    right: auto;
    width: 100vw;
    padding: 0 60px;
  `}

  ${media.xsMax`
    padding: 0 40px;
  `}
`

export const ContentIconClose = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: all 0.8s;
  ${media.mdMax`
    width: 50px;
    height: 50px;
    position: fixed;
    left: auto; 
    right: 20px;
    top: 20px;
  `}
`

export const StyledContentText = styled.div`
  transform: skew(25deg);

  ${media.mdMax`
    transform: none;
    max-width: 381px;
    width: 100%;
  `}
`

export const InfoContent = styled.div`
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: 5px;
  margin-bottom: 100px;

  ${media.xlMax`
    margin-left: 55px;
    font-size: 16px;
  `}

  ${media.lgMax`
    margin-bottom: 50px;
  `}

  ${media.mdMax`
    margin-left: 0;
    margin-bottom: 100px;
  `}
`

export const SendContent = styled.div`
  margin-bottom: 150px;

  ${media.lgMax`
    margin-bottom: 50px;
  `}

  ${media.mdMax`
    margin-bottom: 150px;
  `}
  
  ${media.xsMax`
    margin-bottom: 100px;
  `}
`

export const SendText = styled.div`
  display: flex;
  align-items: center;
`

export const StyledLocation = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.white};
`

export const StyledProyectSend = styled.p`
  font-family: 'Bebas Neue';
  font-size: 30px;
  margin-right: 20px;
  margin-bottom: 0;
  cursor: pointer;

  ${media.mdMax`
    z-index: 2;
  `}

  ${media.xsMax`
    font-size: 25px;
  `}
`

export const IconNext = styled(Icon)`
  cursor: pointer;

  ${media.mdMax`
    z-index: 2;
  `}

  ${media.xsMax`
    width: 30px;
    height: 30px;
  `}
`

export const ContactText = styled.p`
  font-family: 'Bebas Neue';
  font-size: 90px;
  line-height: 1;
  color: rgba(61, 185, 236, 0.1);
  margin-bottom: 0;
  position: relative;
  top: -35px;
  z-index: -1;

  ${media.lgMax`
    font-size: 80px;
  `}

  ${media.mdMax`
    z-index: 0;
    top: -32px;
  `}

  ${media.xsMax`
    font-size: 56px;
    top: -17px;
  `}
`
